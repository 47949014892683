@import url("https://fonts.googleapis.com/css2?family=Duru+Sans&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/SemiBold\ -\ Copy.ttf");
  font-display: swap;
}

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/IntegralCF-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/Nunito\ Sans\ ExtraBold\ 800.ttf");
  font-display: swap;
}

@font-face {
  font-family: GilroyBlack;
  src: url("../font-family/gilroy-black.ttf");
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: Roboto;
  scroll-behavior: smooth;
}

.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  display: none;
}

.MuiBox-root.css-1gsv261 {
  border: none;
}

.react-tel-input .flag-dropdown {
  display: none !important;
}
.react-tel-input input {
  padding: 15px !important;
}

.profile-edit-move-enter {
  transform: translateX(100%);
}

.profile-edit-move-enter-active {
  transform: translateX(0);
  transition: transform 1s ease; /* Adjust the duration and easing function as needed */
}

.profile-edit-move-exit {
  transform: translateX(0);
}

.profile-edit-move-exit-active {
  transform: translateX(100%);
  transition: transform 1s ease; /* Adjust the duration and easing function as needed */
}

/* @media screen and (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
} */

.Down-container {
  background: url("./assets/images/downBg.png") bottom center;
  background-size: cover;
  overflow: hidden;
}

.Down-container p {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* optional: for better text readability */
  padding: 20px;
  border-radius: 10px;
}

/* form start */

.containerPas {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerPas .passwordInp {
  background-color: #2e5b8b;
  padding: 5px 10px;
  border-radius: 15px;
  color: #fff;
}

.containerPas .passwordInp::placeholder {
  color: #fff;
}

.MuiTypography-root.MuiTypography-body1.MuiMultiInputDateRangeField-separator.css-1x58qyc-MuiTypography-root-MuiMultiInputDateRangeField-separator {
  display: none !important;
}
.MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff !important;
  border-radius: none !important;
  margin: 0 !important;
  padding: 0 12px !important;
  width: 100% !important;
  height: 55px !important;
  outline: none !important;
}

.S9gUrf-YoZ4jf {
  display: none !important;
  height: 0 !important;
}

.vagonsvg text {
  fill: #000 !important;
  fill-opacity: 1;
}

div[role="presentation"] .dDftEu {
  color: #000 !important;
}
div[role="presentation"] .ebzlGz,
div[role="presentation"] .BZWSg {
  color: #0057BE !important;
}

div[role="presentation"] .ebzlGz::after,
div[role="presentation"] .gBDkEr,
div[role="presentation"] .jMjwEK,
div[role="presentation"] .kfICJX {
  background: #0057BE !important;
  background-color: #0057BE !important;
}
div[role="presentation"] .gBDkEr:focus {
  box-shadow: #0057BE 0px 0px 8px !important;
}

div[role="presentation"] .iTpRNk:hover {
  border: 1px solid #0057BE !important;
}

@media (max-width: 768px) {
  .ant-picker {
    width: 100% !important;
  }

  :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: block;
  }
  :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-decade-panel, :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-year-panel, :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-quarter-panel, :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-month-panel, :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-week-panel, :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-date-panel, :where(.css-dev-only-do-not-override-1kf000u).ant-picker-dropdown .ant-picker-time-panel {
    width: 100% !important;
  }
}


:where(.css-dev-only-do-not-override-1kf000u).ant-picker-outlined {
  background: transparent !important;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .css-19kzrtu {
    padding: 10px !important;
  }
}