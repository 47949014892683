
  .slider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 80%; */
    margin: 0 auto;
    position: relative;
  }
  
  /* Previous and Next buttons */
  .slider-button {
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    margin: 0 10px;
  }
  
  .slider-button:hover {
    background-color: #555;
  }
  
  /* containers */
  .container-Clear,
  .container-Rain,
  .container-cloud,
  .container-Thunderstorm,
  .container-Dust
  .container-Clouds {
    display: none;
  }
  .container-Clear.active,
  .container-Rain.active,
  .container-Thunderstorm.active,
  .container-cloud.active,
  .container-Dust,
  .container-Clouds.active {
    display: block;
  }
  
  /* Text */
  .city,
  .day,
  .grade {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  .city {
    color: whitesmoke;
    font-size: 12px;
    font-weight: 300;
  }
  
  .day {
    color: whitesmoke;
    font-size: 25px;
    font-weight: 900;
  }
  
  .grade {
    color: whitesmoke;
    font-size: 40px;
    font-weight: 400;
  }
  
  /* icon Github */
  .github-container {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    z-index: 999;
  }
  
  .github-container p {
    color: #757575;
  }
  
  .github-container i {
    color: #757575;
  }
  
  .github-container i {
    color: #757575;
    transition: transform 0.3s;
  }
  
  .github-container i:hover {
    transform: scale(1.5);
  }
  
  /* Clear */
  
  .Clear {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ffcc00;
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    animation: pulse 2s ease-in-out infinite, glow 3s ease-in-out infinite;
  }

  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    }
    50% {
      box-shadow: 0 0 30px rgba(255, 204, 0, 0.9);
    }
    100% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    }
  }
  
  .container-Clear {
    position: relative;
    /* width: 300px; */
    height: 200px;
  }
  
  .box-shadow-Clear {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 95%;
    height: 85%;
    background-color: #ffcc00;
    border-radius: 20px;
    opacity: 0.9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  
  .content-Clear {
    position: relative;
    z-index: 1;
    background-color: #60a7c4;
    border-radius: 20px;
    width: 320px;
    height: 165px;
    padding: 20px 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-Clear {
    flex: 1;
    height: auto;
    width: 80%;
  }
  
  .text-Clear {
    margin-bottom: 20px;
    flex: 1;
  }
  
  .text-Clear p {
    margin-top: 13px;
    margin-left: 20px;
    margin-bottom: -15px;
  }
  
  
  
  /* Clear & CLOUD */
  .sun-inner,
  .cloud-sun-inner {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .sun-inner {
    position: relative;
    width: 80px;
    top: 20px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffcc00;
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    animation: pulse 2s ease-in-out infinite, glow 3s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    }
    50% {
      box-shadow: 0 0 30px rgba(255, 204, 0, 0.9);
    }
    100% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    }
  }
  
  .cloud-sun-inner {
    position: relative;
    width: 125px;
    height: 65px;
    border-radius: 90px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .cloud-sun-inner::before,
  .cloud-sun-inner::after {
    content: "";
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: white;
    border-radius: 36px;
  }
  
  .cloud-sun-inner::before {
    top: -30px;
    left: 30px;
  }
  
  .cloud-sun-inner::after {
    top: -18px;
    right: 30px;
  }
  
  @keyframes moveCloud {
    from {
      transform: translateX(-10px);
    }
    to {
      transform: translateX(10px);
    }
  }
  
  .cloud-sun-inner {
    animation: moveCloud 4s linear infinite alternate;
  }
  
  .container-Clouds {
    position: relative;
    width: 300px;
    height: 200px;
  }
  
  .box-shadow-Clouds {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 95%;
    height: 85%;
    background-color: white;
    border-radius: 20px;
    opacity: 0.9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  
  .content-Clouds {
    position: relative;
    z-index: 1;
    background-color: #2b6f8ad3;
    border-radius: 20px;
    width: 320px; 
    height: 160px;
    padding: 20px 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-Clouds {
    flex: 1;
    height: auto;
    margin-bottom: 20px;
    width: 80%;
  }
  
  .text-Clouds {
    margin-bottom: 20px;
    flex: 1;
  }
  
  .text-Clouds p {
    margin-top: 13px;
    margin-left: 20px;
    margin-bottom: -15px;
  }
  
  
  /* RAIN */
  
  .container-Rain {
    position: relative;
    width: 300px;
    height: 200px;
  }
  
  .box-shadow-Rain {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 95%;
    height: 85%;
    background-color: #015b7fe5;
    border-radius: 20px;
    opacity: 0.9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  
  .content-Rain {
    position: relative;
    z-index: 1;
    background-color: #2b6f8a67;
    border-radius: 20px;
    width: 320px;
    height: 165px;
    padding: 20px 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-Rain {
    flex: 1;
    height: auto;
    margin-top: 20px;
    width: 80%;
  }
  
  .text-Rain {
    margin-bottom: 20px;
    flex: 1;
  }
  
  .text-Rain p {
    margin-top: 13px;
    margin-left: 20px;
    margin-bottom: -15px;
  }
  
  .raindrop {
    position: absolute;
    width: 8px;
    height: 20px;
    background-color: #015b7f;
    opacity: 0.8;
    left: calc(50% - 4px + (100px * var(--offset)));
    top: calc(100% + 30px);
  }
  
  .raindrop:nth-child(1) {
    --offset: 0;
    animation: fallRain 1.5s linear infinite;
  }
  
  .raindrop:nth-child(2) {
    --offset: 0.2;
    animation: fallRain 1.8s linear infinite;
  }
  
  .raindrop:nth-child(3) {
    --offset: 0.4;
    animation: fallRain 1.7s linear infinite;
  }
  
  @keyframes fallRain {
    0% {
      transform: translateY(-10px);
      opacity: 0.8;
    }
    100% {
      transform: translateY(200px);
      opacity: 0.4;
    }
  }
  
  .rain-cloud {
    position: relative;
    width: 130px;
    height: 70px;
    border-radius: 90px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .rain-cloud::before,
  .rain-cloud::after {
    content: "";
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: white;
    border-radius: 36px;
  }
  
  .rain-cloud::before {
    top: -30px;
    left: 30px;
  }
  
  .rain-cloud::after {
    top: -18px;
    right: 30px;
  }
  
  @keyframes moveCloud {
    from {
      transform: translateX(-10px);
    }
    to {
      transform: translateX(10px);
    }
  }
  
  .rain-cloud {
    animation: moveCloud 4s linear infinite alternate;
  }
  
  /* CLOUD */
  
  .cloud {
    position: relative;
    width: 130px;
    height: 70px;
    border-radius: 90px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .cloud::before,
  .cloud::after {
    content: "";
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: white;
    border-radius: 36px;
  }
  
  .cloud::before {
    top: -30px;
    left: 30px;
  }
  
  .cloud::after {
    top: -18px;
    right: 30px;
  }
  
  @keyframes moveCloud {
    from {
      transform: translateX(-10px);
    }
    to {
      transform: translateX(10px);
    }
  }
  
  .cloud {
    animation: moveCloud 4s linear infinite alternate;
  }
  
  .container-cloud {
    position: relative;
    width: 300px;
    height: 200px;
  }
  
  .box-shadow-cloud {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 95%;
    height: 85%;
    background-color: #012b3b;
    border-radius: 20px;
    opacity: 0.9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  
  .content-cloud {
    position: relative;
    z-index: 1;
    background-color: #041e29;
    border-radius: 20px;
    width: 230px;
    height: 130px;
    padding: 20px 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-cloud {
    flex: 1;
    height: auto;
    margin-top: 20px;
    width: 80%;
  }
  
  .text-cloud {
    margin-bottom: 20px;
    flex: 1;
  }
  
  .text-cloud p {
    margin-top: 13px;
    margin-left: 20px;
    margin-bottom: -15px;
  }
  
  
  /* STORM */
  .bolt {
    margin: 2rem auto;
    border-top: 75px solid yellow;
    border-left: 5px solid transparent;
    border-right: 10px solid transparent;
    height: 0px;
    width: 15px;
    transform: skew(-25deg) scale(0.8);
    opacity: 0.7;
    animation: appearBolt 4s ease-in-out forwards infinite;
  }
  
  @keyframes appearBolt {
    0%,
    100% {
      opacity: 0.7;
    }
    50% {
      opacity: 1;
    }
  }
  
  .bolt:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 15px;
    top: -21px;
    border-top: 75px solid yellow;
    border-right: 26.25px solid transparent;
  }
  
  .raindrop-storm {
    position: absolute;
    width: 8px;
    height: 20px;
    background-color: #015b7f;
    opacity: 0.8;
    left: calc(50% - 4px + (100px * var(--offset)));
    top: calc(100% + 30px);
  }
  
  .raindrop-storm:nth-child(1) {
    --offset: 0;
    animation: fallRain 1.5s linear infinite;
  }
  
  .raindrop-storm:nth-child(2) {
    --offset: 0.2;
    animation: fallRain 1.8s linear infinite;
  }
  
  .raindrop-storm:nth-child(3) {
    --offset: 0.4;
    animation: fallRain 1.7s linear infinite;
  }
  
  @keyframes fallRain {
    0% {
      transform: translateY(-10px);
      opacity: 0.8;
    }
    100% {
      transform: translateY(200px);
      opacity: 0.4;
    }
  }
  
  .cloud-storm {
    position: relative;
    width: 130px;
    height: 70px;
    border-radius: 90px;
    background-color: grey;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .cloud-storm::before,
  .cloud-storm::after {
    content: "";
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: grey;
    border-radius: 36px;
  }
  
  .cloud-storm::before {
    top: -30px;
    left: 30px;
  }
  
  .cloud-storm::after {
    top: -18px;
    right: 30px;
  }
  
  @keyframes moveCloud {
    from {
      transform: translateX(-10px);
    }
    to {
      transform: translateX(10px);
    }
  }
  
  .cloud-storm {
    animation: moveCloud 4s linear infinite alternate;
  }
  
  .container-Thunderstorm {
    position: relative;
    width: 300px;
    height: 200px;
  }
  
  .box-shadow-Thunderstorm {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 95%;
    height: 85%;
    background-color: grey;
    border-radius: 20px;
    opacity: 0.9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  
  .content-Thunderstorm {
    position: relative;
    z-index: 1;
    background-color: #4ba2c5;
    border-radius: 20px;
    width: 320px;
    height: 165px;
    padding: 20px 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-Thunderstorm {
    flex: 1;
    height: auto;
    margin-bottom: 5px;
    width: 80%;
  }
  
  .text-Thunderstorm {
    margin-bottom: 20px;
    flex: 1;
  }
  
  .text-Thunderstorm p {
    margin-top: 13px;
    margin-left: 20px;
    margin-bottom: -15px;
  }
  
  
  @media (max-width: 768px) {
    body {
      font-size: 16px; 
    }
  
    .slider-container {
      flex-direction: column; 
    }
  
    .slider-button {
      margin: 60px 0; 
    }
  }




  /* Haze */
  .haze {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    }
    50% {
      box-shadow: 0 0 30px rgba(255, 204, 0, 0.9);
    }
    100% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
    }
  }
  
  .haze {
    position: relative;
    width: 125px;
    height: 65px;
    margin-top: 50px;
    border-radius: 90px;
    background-color: #7a7a7a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .haze::before,
  .haze::after {
    content: "";
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: #7a7a7a;
    border-radius: 36px;
  }
  
  .haze::before {
    top: -30px;
    left: 30px;
  }
  
  .haze::after {
    top: -18px;
    right: 30px;
  }
  
  @keyframes moveCloud {
    from {
      transform: translateX(-10px);
    }
    to {
      transform: translateX(10px);
    }
  }
  
  .haze {
    animation: moveCloud 4s linear infinite alternate;
  }
  
  .container-Haze {
    position: relative;
    width: 300px;
    height: 200px;
  }
  
  .box-shadow-Haze {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 95%;
    height: 85%;
    background-color: #000000;
    border-radius: 20px;
    opacity: 0.9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  
  .content-Haze {
    position: relative;
    z-index: 1;
    background-color: #484848;
    border-radius: 20px;
    width: 320px; 
    height: 160px;
    padding: 20px 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-Haze {
    flex: 1;
    height: auto;
    margin-bottom: 20px;
    width: 80%;
  }
  
  .text-Haze {
    margin-bottom: 20px;
    flex: 1;
  }
  
  .text-Haze p {
    margin-top: 13px;
    margin-left: 20px;
    margin-bottom: -15px;
  }



/* dust */
.dust-particle {
  position: absolute;
  top: 0;
  left: 0;
}

.sun-inner {
  position: relative;
  width: 80px;
  top: 20px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffcc00;
  box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  animation: pulse 2s ease-in-out infinite, glow 3s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 204, 0, 0.9);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  }
}

.dust-particle {
  position: relative;
  width: 125px;
  height: 65px;
  margin-top: 50px;
  border-radius: 90px;
  background-color: #c2b280;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dust-particle::before,
.dust-particle::after {
  content: "";
  position: absolute;
  width: 72px;
  height: 72px;
  background-color: #c2b280;
  border-radius: 36px;
}

.dust-particle::before {
  top: -30px;
  left: 30px;
}

.dust-particle::after {
  top: -18px;
  right: 30px;
}

@keyframes moveCloud {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(10px);
  }
}

.dust-particle {
  animation: moveCloud 4s linear infinite alternate;
}

.container-Dust {
  position: relative;
  width: 300px;
  height: 200px;
}

.box-shadow-Dust {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 95%;
  height: 85%;
  background-color: #d9c79f;
  border-radius: 20px;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.content-Dust {
  position: relative;
  z-index: 1;
  background-color: #b4a083;
  border-radius: 20px;
  width: 320px; 
  height: 160px;
  padding: 20px 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-Dust {
  flex: 1;
  height: auto;
  margin-bottom: 20px;
  width: 80%;
}

.text-Dust {
  margin-bottom: 20px;
  flex: 1;
}

.text-Dust p {
  margin-top: 13px;
  margin-left: 20px;
  margin-bottom: -15px;
}



.container-Unknown {
  position: relative;
  height: 200px;
}

.box-shadow-Unknown {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 95%;
  height: 85%;
  background-color: #d3d3d3; /* Neutral grey background for unknown weather */
  border-radius: 20px;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.content-Unknown {
  position: relative;
  z-index: 1;
  background-color: #a9a9a9; /* Slightly darker grey */
  border-radius: 20px;
  width: 320px;
  height: 165px;
  padding: 20px 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-Unknown {
  flex: 1;
  height: auto;
  width: 80%;
}

.text-Unknown {
  margin-bottom: 20px;
  flex: 1;
}

.text-Unknown p {
  margin-top: 13px;
  margin-left: 20px;
  margin-bottom: -15px;
}

/* Optionally add some animation for default weather */
@keyframes unknownPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.content-Unknown {
  animation: unknownPulse 3s ease-in-out infinite;
}
