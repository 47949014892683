.Ticket {
  width: 100%;
  /* margin-bottom: 50px; */
}

.Ticket .checklist {
  width: 100%;
  height: auto;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  padding: 30px 20px;
}

.Ticket .checklist .transfer {
  border-bottom: 1px solid gray;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.Ticket .checklist .transfer h3 {
  margin-bottom: 8px;
  font-size: 17px;
  font-weight: 700;
}

.Ticket .checklist .transfer .checkbox {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}

.Ticket .checklist .transfer .checkbox input {
  padding: 6px;
}

.Ticket .checklist .transfer .checkbox label {
  padding: 6px;
}

.Ticket .checklist .company {
  margin-top: 4px;
  padding-top: 4px;
}

.Ticket .checklist .company h3 {
  margin-bottom: 8px;
  font-size: 17px;
  font-weight: 700;
}

.Ticket .checklist .company .checkbox {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}

.Ticket .checklist .company .checkbox input {
  padding: 6px;
}

.Ticket .checklist .company .checkbox label {
  padding: 6px;
}

.Ticket .box {
  /* width: 92%; */
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  padding: 0px 10px 0 20px;
}

.ShopTicket .box .container-box,
.Ticket .box .container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Ticket .box .left {
  width: 80%;
}

.ShopTicket .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
.ShopTicket .css-15v22id-MuiAccordionDetails-root,
.Ticket .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
.Ticket .css-15v22id-MuiAccordionDetails-root {
  padding: 0;
}

.ShopTicket .css-1elwnq4-MuiPaper-root-MuiAccordion-root,
.Ticket .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}

.ShopTicket .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before,
.Ticket .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.Ticket .container-box-2 .left {
  width: 100%;
}

.Ticket .box .left .top {
  display: flex;
}

.Ticket .box .left .top h2 span.type {
  font-size: 15px;
}

.Ticket .box .left .top h2 span.class {
  font-size: 15px;
  margin: 0px 10px;
}

.Ticket .box .left .top h2 .css-i4bv87-MuiSvgIcon-root {
  font-size: 15px;
}

.ShopTicket .box .left .bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ShopTicket .box .left .bottom p.data,
.Ticket .box .left .bottom p.data {
  display: flex;
  font-size: 15px;
}

.ShopTicket .box .left .bottom p.data svg,
.Ticket .box .left .bottom p.data svg {
  font-size: 15px;
  color: gray;
}

.ShopTicket .box .left .bottom h2,
.Ticket .box .left .bottom h2 {
  font-weight: 500;
  font-size: 20px;
}

.ShopTicket .box .left .bottom h2.time,
.Ticket .box .left .bottom h2.time {
  font-style: 14px;
  font-weight: 700;
}

.ShopTicket .box .left .bottom .map,
.Ticket .box .left .bottom .map {
  display: flex;
}

.ShopTicket .box .left .bottom .map .from,
.Ticket .box .left .bottom .map .from {
  font-weight: 500;
  font-size: 10px;
  text-align: center;
}

.ShopTicket .box .left .bottom .map .middle,
.Ticket .box .left .bottom .map .middle {
  font-weight: 500;
  font-size: 10px;
  text-align: center;
}

.ShopTicket .box .left .bottom .middle .af::after,
.Ticket .box .left .bottom .map .middle .af::after {
  content: "";
  border-radius: 50%;
  border: 3px solid gray;
  background-color: gray;
}

.ShopTicket .box .left .bottom .map .to,
.Ticket .box .left .bottom .map .to {
  font-weight: 500;
  font-size: 10px;
  text-align: center;
}

.Ticket .box .left .bottom .map svg {
  color: #605dec;
}

.Ticket .box .left .bottom .map div.middle {
  color: gray;
}

.Ticket .box .left .bottom .map div.middle svg {
  color: gray;
}

.ShopTicket .box .left div.line,
.Ticket .box .left div.line {
  text-align: center;
}

.ShopTicket .box .left div.line div,
.Ticket .box .left div.line div {
  width: 100%;
  height: 2px;
  background-color: #cccccc;
}

.Ticket .box .right {
  display: flex;
  flex-direction: column;
}

.Ticket .box .right p.rate {
  font-weight: 700;
}

.Ticket .box .right p.luggage {
  margin: 30px 0;
}

.Ticket .box .right button.bgBlue {
  background-color: #2E5B8B;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 12px 10px;
  cursor: pointer;
}

.Ticket .box .right button.bgWhite {
  margin-top: 10px;
  background-color: white;
  border: 2px solid #605dec;
  color: #605dec;
  border-radius: 8px;
  padding: 10px 20px;
}

.history-search-container::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.checklistContainer::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
  padding: 10px;
}

.checklistContainer::-webkit-scrollbar-thumb {
  background-color: #2E5B8B;
}

.checklistContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.history-search-container::-webkit-scrollbar-thumb {
  background-color: #2E5B8B;
  border-radius: 10px;
}

.center {
  width: 100%;
  height: 100%;
}

.details {
  margin: 20px 0;
  padding: 0 5px;
  box-sizing: border-box;
}
.percentage {
  font-size: 24px;
  font-weight: 700;
}
.progress {
  width: calc(100% - 10px);
  height: 8px;
  background: rgba(100, 100, 100, 0.2);
  margin: auto;
  border-radius: 2mm;
  position: relative;
  margin-bottom: 15px;
}

.progress::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 8px;
  border-radius: 2mm;
  background: #009688;
  animation: progress 15s linear infinite, color 0.8s linear infinite;
}

@keyframes progress {
  from {
    width: 0;
  }
}

@keyframes color {
  0%,
  100% {
    background-color: rgba(2, 203, 186, 0.592); /* Darker shade */
  }
  50% {
    background-color: #207a85; /* Lighter shade */
  }
}

@media screen and (max-width: 1024px) {
  .Ticket .box .left .bottom .map {
    display: none;
  }

  .Ticket .box .left div.line {
    display: none;
  }

  .Ticket .box .left .namCity {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .Ticket .box .right {
    display: none;
  }
  .Ticket .box .left {
    border: none;
    width: 100%;
  }
  .Ticket .box .left .top h2 span.type {
    display: none;
  }
  .Ticket .box .left .top h2 span.class {
    display: none;
  }
  .Ticket .box .left .top .sum {
    display: block;
    font-size: 18px;
  }
}

@media screen and (max-width: 568px) {
  .Ticket .box {
    padding: 15px 10px;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 400px) {
  .Ticket .box .left .bottom h2.time {
    font-size: 17px;
  }
  .Ticket .box .left .bottom .tex {
    font-size: 14px;
  }
  .Ticket .box .left .top .sum {
    /* margin-left: 10px; */
    color: #2d5daf;
  }
  .Ticket .box .bottom .watch {
    font-size: 16px;
  }
}

.loaderWrapper {
  position: relative;
  width: 100%;
}

.loaderHo {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background-color: rgb(141, 140, 140);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: animFw 20s linear infinite;
}

.airplaneIcon {
  position: absolute;
  left: 0;
  top: -10px;
  animation: moveAirplane 20s linear infinite;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes moveAirplane {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
